<script setup>
import { useTheme } from "vuetify";
import NovuNotification from "@/components/NovuNotification.vue";
import UserMenu from "@/components/layout/UserMenu.vue";
import { useDark, useToggle } from "@vueuse/core";
import { useThemeStore } from "@/stores/themeStore";
import { useSidebarStore } from "@/stores/sidebarStore";
import { useUserStore } from "@/stores/user";

const themeStore = useThemeStore();
const sidebarStore = useSidebarStore();
const userStore = useUserStore();

const theme = useTheme();
const isDark = useDark({
  onChanged(dark) {
    themeStore.updateTheme(dark ? "dark" : "light");
    theme.global.name.value = dark ? "dark" : "light";
  },
});
const toggleDark = useToggle(isDark);

function toggleTheme() {
  toggleDark();
}

const getNavIcon = () => {
  if (sidebarStore.showRail) {
    return "mdi-chevron-right";
  } else {
    return "mdi-chevron-left";
  }
};
</script>

<template>
  <v-app-bar
    elevation="0"
    density="compact"
    flat
    height="65"
    class="border-b"
  >
    <v-btn
      density="comfortable"
      :icon="getNavIcon()"
      @click="sidebarStore.collapse(!sidebarStore.showRail)"
    ></v-btn>

    <v-app-bar-title>
      <div>
        L&M <span class="font-weight-bold">Connect</span><span
          class="text-orange font-weight-bold"
        >3</span>
      </div>
    </v-app-bar-title>
    <template v-slot:append>
      <!-- <NovuNotification>
        <v-btn
          density="compact"
          icon="mdi-bell"
        ></v-btn>
      </NovuNotification> -->

      <v-btn
        @click="toggleTheme"
        density="compact"
        class="ml-2 mr-2"
        icon="mdi-theme-light-dark"
      ></v-btn>
      <UserMenu />
    </template>
  </v-app-bar>
</template>
