import { onMounted, watch, reactive, ref, Ref } from "vue";
import { shortDateWithTimeTz } from "@/utils/dateUtils";
// @ts-ignore
// import { AgGridVue } from "ag-grid-vue3";
// import { LicenseManager } from "ag-grid-enterprise";
import { useThemeStore } from "@/stores/themeStore";
// import { connectToServerApi } from "@/utils/agGridUtils";
import {
  ColDef, GridOptions, IProvidedFilterParams, RowClassParams, colorSchemeDark, colorSchemeLight, themeQuartz} from 'ag-grid-enterprise';


const themeStore = useThemeStore();

export const c3Theme = themeQuartz.withParams({
    // backgroundColor: "#1f2836",
    // browserColorScheme: "dark",
    browserColorScheme: "inherit",
    chromeBackgroundColor: {
      ref: "foregroundColor",
      mix: 0.07,
      onto: "backgroundColor"
    },
    fontFamily: {
      googleFont: "Inter"
    },
    // foregroundColor: "#FFF",
    headerFontSize: 14,
    // headerBackgroundColor: "#F2F2F2",
    wrapperBorderRadius: "0px",
    wrapperBorder: false,
  }).withPart(themeStore.theme == "dark" ? colorSchemeDark : colorSchemeLight);





export const defaultColDef: Ref<ColDef> = ref({
  sortable: true,
  floatingFilter: false,
  // suppressMenu: true,
  resizable: true,
  width: 120,
  filterParams: {
    maxNumConditions: 1,
    closeOnApply: true,
    filterOptions: ["contains"],
    buttons: ["apply"],
  },
});

export const gridOptions: GridOptions = {
  rowModelType: "serverSide",
  loadThemeGoogleFonts: true,
  // rowSelection: "multiple",
  enableCharts: true,
  enableRangeSelection: true,
  cellSelection: true,
  enableAdvancedFilter: true,
  autoSizeStrategy: {
    type: "fitGridWidth",
    defaultMinWidth: 50,
  },
  rowHeight: 42,
  getRowStyle: (params: RowClassParams): { [key: string]: string } | undefined => {
    if (params.data?.active === false) {
      return {
        'color': 'red',
        // "margin-left": '10px',
        // "margin-right": '10px'
      };
    }
    return {
      // "margin-left": '10px',
      // "margin-right": '10px'
    };
  }
};

export const valueGetters = {
  active: (params: any) => params.data?.active === true ? 'Active' : 'Disabled',
  created_at: (params: any) => shortDateWithTimeTz(params.data?.created_at)
};

export const dateFilterParams = {
  filterOptions: ["equals", "greaterThanOrEqual", "lessThanOrEqual", "inRange", "blank"],
  includeBlanksInLessThan: true,
  inRangeInclusive: true,
};

export const textFilterParams = {
  filterOptions: ["contains", "equals", "blank", "notEqual"],
  includeBlanksInLessThan: true,
  inRangeInclusive: true,
};

var defaultFilterParams: IProvidedFilterParams = { readOnly: true };


// export const colDefs = ref([
//   {
//     colId: "id",
//     field: "id",
//     headerName: "Id",
//     cellDataType: "number",
//     width: 70,
//     filter: "agNumberColumnFilter",
//   },
//   {
//     field: "category",
//     headerName: "Category",
//     // cellDataType: "text",
//     width: 70,
//     filter: "agSetColumnFilter",
//     filterParams: {
//       values: ['1', '2', '3'],
//     },
//   },
//   {
//     colId: "created_by.name",
//     field: "created_by.name",
//     headerName: "Created By",
//     cellDataType: "text",
//     width: 70,
//     filter: "agNumberColumnFilter",
//   },
//   // {
//   //   field: "name",
//   //   headerName: "User",
//   //   cellDataType: "text",
//   //   width: 150,
//   //   filter: "agTextColumnFilter",
//   //   cellRenderer: UserNameAndEmailRenderer,
//   //   filterParams: textFilterParams,
//   // },
//   // {
//   //   field: "email",
//   //   headerName: "Email",
//   //   cellDataType: "text",
//   //   width: 100,
//   //   hide: true,
//   //   filter: "agTextColumnFilter",
//   // },
//   // {
//   //   field: "tenant.name",
//   //   headerName: "Tenant",
//   //   cellDataType: "text",
//   //   width: 135,
//   //   filter: "agTextColumnFilter",
//   //   filterParams: textFilterParams,
//   // },
//   // {
//   //   field: "active",
//   //   headerName: "Status",
//   //   cellDataType: "text",
//   //   width: 135,
//   //   valueGetter: (params: any) => params.data?.active === true ? 'Active' : 'Disabled',
//   //   filter: 'agSetColumnFilter',
//   //   filterParams: {
//   //     values: ['Active', 'Disabled'],
//   //     defaultToNothingSelected: false,
//   //     defaultValue: ['Active'],
//   //     valueFormatter: (params: any) => params.value === 'Active' ? 'Active' : 'Disabled',
//   //     keyCreator: (params: any) => params.value === 'Active' ? '1' : '0'
//   //   },
//   // },
//   {
//     field: "created_at",
//     headerName: "Created At",
//     cellDataType: "text",
//     width: 135,
//     filter: "agDateColumnFilter",
//     filterParams: dateFilterParams,
//   },
//   // {
//   //   headerName: "Actions",
//   //   width: 50,
//   //   cellRenderer: EditDeleteRenderer,
//   //   cellRendererParams: {
//   //     editUrl: `/users`,
//   //     showEdit: true
//   //   }
//   // }
// ]);



